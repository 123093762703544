<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <div v-if="loading">
      <v-skeleton-loader
        :loading="loading"
        type="card-heading, list-item@5"
        class="mb-4"
      ></v-skeleton-loader>
      <v-skeleton-loader
        :loading="loading"
        type="table-tbody"
      ></v-skeleton-loader>
    </div>
    <v-card v-else>
      <v-card-title class="pb-0">
        {{ forEdit ? "Edit Contract" : "Add Contract" }}
      </v-card-title>
      <v-card-text class="pt-0">
        <v-container class="pa-0">
          <v-row dense>
            <v-col sm="12" md="4">
              <BaseText label="Contract Name" v-model="contract.name" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <div v-if="forEdit">
        <v-card-title>
          Contract Documents
        </v-card-title>
        <v-simple-table v-if="contractsDocuments.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th>File Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="file in contractsDocuments" :key="file.url">
                <td>{{ file.fileName }}</td>
                <td style="width: 50px">
                  <v-btn small color="primary" @click="viewFile(file)">
                    View File
                  </v-btn>
                </td>
                <td style="width: 50px">
                  <v-btn small color="error" @click="removeFile(file)">
                    Remove
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-text v-else>No Items</v-card-text>
        <v-card-text>
          <FileUploadButton
            upload-text="Add Contract Document"
            :upload-location="`contract-documents/${this.client.id}/`"
            @on:file="documentUploaded"
          />
        </v-card-text>
        <v-divider></v-divider>
      </div>
      <v-card-actions>
        <v-btn color="primary" :loading="saving" @click="saveAndNavigate">
          Save
        </v-btn>
        <v-btn color="error" :to="`/clients/${this.client.id}`">Cancel</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="modal" max-width="750">
      <v-card>
        <v-card-title>New Price List</v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedPricingLevelId"
            :items="pricingLevels"
            item-text="category"
            item-value="id"
            label="Select a Pricing Level"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" small @click="savePricingList">
            Add Price List
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="priceModal" max-width="750">
      <v-card>
        <v-card-title class="headline">Add a new price item</v-card-title>

        <v-card-text>
          <v-select
            v-model="selectedStockId"
            :items="stock"
            item-text="stockDescription"
            item-value="id"
            label="Select a Stock Item"
          />
          <v-text-field label="Price" v-model.number="price"></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="savePriceItem">Add</v-btn>
          <v-btn color="red darken-1" text @click="priceModal = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="my-2" v-if="forEdit">
      <v-card-title>Contract Price List</v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" class="mr-2" small @click="priceModal = true">
          <v-icon left>mdi-import</v-icon>
          Import New Item
        </v-btn>
        <v-btn color="primary" class="mr-2" small @click="modal = true">
          <v-icon left>mdi-upload</v-icon>
          Import Price List
        </v-btn>
        <v-btn color="error" small @click="removePricingList">
          <v-icon left>mdi-delete</v-icon>
          Remove Price List
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-if="stockPricingList.length">
      <hot-table
        :data="stockPricingList"
        :colHeaders="colHeaders"
        :afterChange="afterChange"
        :columns="columns"
        :rowHeaders="true"
        :context-menu="contextMenu"
        licenseKey="non-commercial-and-evaluation"
      />
    </div>
    <v-card v-else><v-card-text>No Items</v-card-text></v-card>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import { HotTable } from "@handsontable/vue";
import FileUploadButton from "@/components/files/file-upload-button";

export default {
  components: {
    FileUploadButton,
    HotTable
  },
  data() {
    return {
      contract: {
        name: "",
        documents: []
      },
      forEdit: false,
      loading: true,
      saving: false,
      removing: false,
      colHeaders: ["Stock Description", "Price", "Initial Copied Price"],
      columns: [
        {
          type: "text",
          readOnly: true,
          data: "stockDescription"
        },
        {
          type: "numeric",
          data: "price"
        },
        {
          type: "numeric",
          readOnly: true,
          data: "initialCopiedPrice"
        }
      ],
      selectedPricingLevelId: null,
      modal: false,
      selectedStockId: null,
      price: null,
      priceModal: false,
      contextMenu: {
        items: [
          {
            key: "delete",
            name: "Remove Stock Item",
            callback: (key, selection) => {
              if (
                !confirm(
                  "Are you sure you want to delete this Price List Item?"
                )
              )
                return;
              const index = selection[0].start.row;
              this.deleteItem(this.stockPricingList[index]);
            }
          }
        ]
      }
    };
  },
  computed: {
    client: get("clients/selectedClient"),
    contractsDocuments: get("clients/contractDocuments"),
    stockPricingList: get("clients/stockPricingList"),
    pricingLevels: get("clients/pricingLevels"),
    stock: get("stock/stock"),
    breadcrumbs() {
      if (!this.client || !this.client.id) return [];

      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Clients",
          to: "/Clients",
          exact: true
        },
        {
          text: this.client.name,
          to: `/Clients/${this.client.id}`,
          exact: true
        },
        {
          text: this.forEdit ? "Edit Contract" : "Add Contract"
        }
      ];
    }
  },
  async created() {
    try {
      await this.loadClient(this.$route.params.id);

      if (this.$route.params.contractId) {
        this.forEdit = true;
        const contractId = parseInt(this.$route.params.contractId);
        await this.loadContractDocuments(this.$route.params.contractId);
        await this.loadContractPriceList(this.$route.params.contractId);
        await this.loadPricingLevel();
        await this.loadStock(1035);

        this.contract = this.client.contracts.find(c => c.id === contractId);
      } else {
        this.forEdit = false;
      }
    } finally {
      this.loading = false;
    }
  },
  methods: {
    loadClient: call("clients/loadClient"),
    saveContract: call("clients/saveContract"),
    saveContractPriceList: call("clients/saveContractPriceList"),
    updateContactPriceList: call("clients/updateContactPriceList"),
    loadContractDocuments: call("clients/loadContractDocuments"),
    loadContractPriceList: call("clients/loadContractPriceList"),
    loadPricingLevel: call("clients/loadPricingLevels"),
    loadStock: call("stock/loadStock"),
    saveContractDocument: call("clients/saveContractDocument"),
    removeContractDocument: call("clients/removeContractDocument"),
    removeContractPriceList: call("clients/removeContractPriceList"),
    saveNewPriceItem: call("clients/saveNewPriceItem"),
    removeContractPriceItem: call("clients/removeContractPriceItem"),
    async documentUploaded(file) {
      const data = {
        url: file.url,
        fileName: file.file.name,
        clientContractId: this.contract.id
      };

      await this.saveContractDocument(data);
    },
    async deleteItem(priceListItem) {
      await this.removeContractPriceItem({
        priceListItemId: priceListItem.id,
        contractId: this.contract.id
      });
    },
    async savePriceItem() {
      await this.saveNewPriceItem({
        contractId: this.contract.id,
        stockId: this.selectedStockId,
        price: this.price
      });
      this.priceModal = false;
    },

    async removePricingList() {
      if (!confirm("Are you sure you want to delete this Contract Price List?"))
        return;

      await this.removeContractPriceList(this.contract.id);
    },

    async savePricingList() {
      await this.saveContractPriceList({
        contractId: this.contract.id,
        pricingLevelId: this.selectedPricingLevelId
      });
      this.modal = false;
    },
    async saveAndNavigate() {
      await this.saveContract(this.contract);
      this.$router.push(`/clients/${this.client.id}`);
    },
    async removeFile(file) {
      if (!confirm("Are you sure you want to delete this Contract Document?"))
        return;
      await this.removeContractDocument(file);
    },
    viewFile(file) {
      const index = this.contractsDocuments.indexOf(file);

      this.$router.push({ path: index.toString(), append: true });
    },

    async saveChanges(changes) {
      const priceUpdates = changes.map(change => {
        const [row, prop, _, value] = change;
        const stock = { ...this.stockPricingList[row] };
        stock[prop] = value;
        return stock;
      });

      const filteredById = priceUpdates.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      try {
        this.loading = true;
        await this.updateContactPriceList(filteredById);
      } finally {
        this.loading = false;
      }
    },
    afterChange(changes) {
      if (changes == null) {
        // ignore
      } else {
        this.saveChanges(changes);
      }
    }
  }
};
</script>
